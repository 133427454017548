import { shallow } from 'zustand/shallow'
import { createWithEqualityFn } from 'zustand/traditional'

type DialogStateType<T> = {
  isOpen: boolean
  isWithoutAnimation?: boolean
  data?: T
}

type NavigationStoreType = {
  newLearningActivity: DialogStateType<null>
  sideMenu: DialogStateType<null>
  newLearningLesson: DialogStateType<null>
  learningActivityDetail: DialogStateType<{ id: string; learningPlanLessonId: string }>
}

const initialState: NavigationStoreType = {
  newLearningActivity: { isOpen: false },
  sideMenu: { isOpen: false },
  newLearningLesson: { isOpen: false },
  learningActivityDetail: { isOpen: false }
}

type DialogKeyType = keyof NavigationStoreType

const useStore = createWithEqualityFn<NavigationStoreType>(() => initialState, shallow)

export const dialogStateStore = {
  setIsOpen: <T>(key: DialogKeyType, data?: T): void => {
    useStore.setState({ [key]: { isOpen: true, isWithoutAnimation: false, data } })
  },
  setIsClosed: (key: DialogKeyType, isWithoutAnimation = false): void => {
    useStore.setState({ [key]: { isOpen: false, isWithoutAnimation } })
  },

  clearStore: (): void => {
    useStore.setState(initialState)
  },
  useStore
}
