import { useNavigate } from 'react-router-dom'

import { Pages } from '@app/config/router/PagesEnum'
import {
  ClassroomInput,
  GetClassroomDetailDocument,
  GetClassroomDetailQuery,
  GetClassroomDetailQueryVariables,
  GetClassroomsDocument,
  GetClassroomsQuery,
  GetClassroomsQueryVariables,
  LearningAssignmentState,
  useChangeLearningAssignmentStateMutation,
  useCreateClassroomMutation,
  useDeleteClassroomMutation,
  useEditClassroomMutation,
  useGetClassroomDetailQuery
} from '@app/data'
import { QueryHookOptions } from '@app/data/graphqlHooks'
import { learningPlanStore } from '@app/modules/learningPlan/store/learningPlanStore'
import { LogUtils } from '@app/utils/LogUtils'

export const useClassroomDetail = (
  classroomId: string | undefined,
  baseOptions?: QueryHookOptions<GetClassroomDetailQuery, GetClassroomDetailQueryVariables>
) => {
  const navigate = useNavigate()
  const { data, ...restOfResult } = useGetClassroomDetailQuery({
    variables: { classroomId: classroomId || '' },
    skip: !classroomId,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    ...baseOptions
  })
  const [changeLearningAssignmentStateMutation, changeLearningAssignmentStateData] =
    useChangeLearningAssignmentStateMutation()
  const [editClassroomMutation, editClassroomMutationData] = useEditClassroomMutation()
  const [createClassroomMutation, createClassroomMutationData] = useCreateClassroomMutation()
  const [deleteClassroomMutation, deleteClassroomMutationData] = useDeleteClassroomMutation()

  const isPending =
    changeLearningAssignmentStateData.loading ||
    editClassroomMutationData.loading ||
    createClassroomMutationData.loading ||
    deleteClassroomMutationData.loading

  const changeLearningAssignmentState = async (
    learningAssignmentId: string,
    newState: LearningAssignmentState
  ): Promise<boolean> => {
    try {
      await changeLearningAssignmentStateMutation({
        variables: { learningAssignmentId, newState },
        update: (cache) => {
          const { classroom } =
            cache.readQuery<GetClassroomDetailQuery, GetClassroomDetailQueryVariables>({
              query: GetClassroomDetailDocument,
              variables: { classroomId: classroomId || '' }
            }) || {}

          cache.updateQuery<GetClassroomDetailQuery, GetClassroomDetailQueryVariables>(
            {
              query: GetClassroomDetailDocument,
              variables: { classroomId: classroomId || '' }
            },
            () => ({
              classroom: classroom!
            })
          )
        }
      })

      return true
    } catch (err) {
      LogUtils.logError(err, 'useGetClassroomDetail', 'changeLearningAssignmentState')
      return false
    }
  }

  const editClassroom = async (classroomInput: ClassroomInput, shouldClearLearningStore = false): Promise<boolean> => {
    try {
      await editClassroomMutation({
        variables: { classroomId: classroomId || '', classroomInput },
        update: (cache) => {
          const { classroom } =
            cache.readQuery<GetClassroomDetailQuery, GetClassroomDetailQueryVariables>({
              query: GetClassroomDetailDocument,
              variables: { classroomId: classroomId || '' }
            }) || {}

          cache.writeQuery<GetClassroomDetailQuery, GetClassroomDetailQueryVariables>({
            query: GetClassroomDetailDocument,
            variables: { classroomId: classroomId || '' },
            data: {
              classroom: {
                ...classroom!,
                ...classroomInput
              }
            }
          })

          if (shouldClearLearningStore) {
            learningPlanStore.clearStore()
          }
        }
      })

      return true
    } catch (err) {
      LogUtils.logError(err, 'useGetClassroomDetail', 'editClassroom')
      return false
    }
  }

  const createClassroom = async (classroomInput: ClassroomInput): Promise<string | undefined> => {
    try {
      const result = await createClassroomMutation({
        variables: { classroomInput },
        update: (cache, { data }) => {
          if (data?.createClassroom) {
            const { classrooms = [] } =
              cache.readQuery<GetClassroomsQuery, GetClassroomsQueryVariables>({
                query: GetClassroomsDocument,
                variables: {}
              }) || {}

            cache.writeQuery<GetClassroomsQuery, GetClassroomsQueryVariables>({
              query: GetClassroomsDocument,
              data: {
                classrooms: [...classrooms, data.createClassroom]
              }
            })
          }
        }
      })

      return result.data?.createClassroom.id
    } catch (err) {
      LogUtils.logError(err, 'useGetClassroomDetail', 'createClassroom')
    }
  }

  const deleteClassroom = async (classroomId: string): Promise<boolean | undefined> => {
    try {
      const result = await deleteClassroomMutation({
        variables: { classroomId },
        update: (cache, { data }) => {
          if (data?.deleteClassroom) {
            const { classrooms = [] } =
              cache.readQuery<GetClassroomsQuery, GetClassroomsQueryVariables>({
                query: GetClassroomsDocument,
                variables: {}
              }) || {}

            cache.writeQuery<GetClassroomsQuery, GetClassroomsQueryVariables>({
              query: GetClassroomsDocument,
              data: {
                classrooms: [...classrooms.filter((c) => c.id !== classroomId)]
              }
            })

            if (classrooms.length > 0) {
              navigate(Pages.CLASSROOM_DETAIL(''), { replace: true })
            }
          }
        }
      })

      return result.data?.deleteClassroom
    } catch (err) {
      LogUtils.logError(err, 'useGetClassroomDetail', 'deleteClassroom')
    }
  }

  return {
    data: data?.classroom,
    isPending,
    ...restOfResult,
    actions: {
      changeLearningAssignmentState,
      editClassroom,
      createClassroom,
      deleteClassroom
    }
  }
}
