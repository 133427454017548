import * as Sentry from '@sentry/react'
import { createClient } from 'graphql-ws'
import { v4 } from 'uuid'
import {
  ApolloClient,
  ApolloLink,
  fromPromise,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  ServerParseError,
  split
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { ServerError } from '@apollo/client/link/utils/throwServerError'
import { getMainDefinition } from '@apollo/client/utilities'

import { getAccessToken, logout } from '@app/app/auth/actions/authActions'
import { authStore } from '@app/app/auth/store/authStore'
import { HTTPStatusCodeEnum } from '@app/model/common/HTTPStatusCodeEnum'

import { apolloStore } from '../../modules/common/store/apolloStore'
import { Constants } from '../constants/Constants'

export const initApolloClient = (): void => {
  const apolloClient = getApolloClient()

  apolloStore.setApolloClient(apolloClient)
}

type ReturnType = ApolloClient<NormalizedCacheObject>

const getApolloClient = (): ReturnType => {
  const cache = new InMemoryCache({})

  const errorLink = onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        switch (err.extensions?.code) {
          case 'UNAUTHENTICATED':
            return fromPromise(
              getAccessToken(3600).catch(() => {
                logout()
                return
              })
            )
              .filter((value) => Boolean(value))
              .flatMap(() => {
                const newAccessToken = authStore.useStore.getState().accessToken
                const oldHeaders = operation.getContext().headers

                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: `Bearer ${newAccessToken}`
                  }
                })
                return forward(operation)
              })
        }
      }
    }
  })

  const operationNameCounts: Record<string, number> = {}

  const retryLink = new RetryLink({
    attempts: {
      max: Constants.MAX_RETRIES,
      retryIf: async (error, operation) => {
        // Increment the count for the current operationName
        const operationName = operation.operationName

        if (operationNameCounts[operationName] >= Constants.MAX_RETRIES - 1) {
          logout()
        }

        try {
          if (error && hasStatusCode(error) && error.statusCode === HTTPStatusCodeEnum.UNAUTHORIZED) {
            if (operationName) {
              if (!operationNameCounts[operationName]) {
                operationNameCounts[operationName] = 0
              }
              operationNameCounts[operationName]++
            }

            await getAccessToken(3600)

            return true
          }

          return false
        } catch (e) {
          return false
        }
      }
    },
    delay: { initial: 300, max: 1000 }
  })

  const customFetch: WindowOrWorkerGlobalScope['fetch'] = async (uri, options) => {
    if (!options) options = {}
    if (!options?.headers) {
      options.headers = {}
    }

    const authToken = authStore.useStore.getState().accessToken || ''

    const traceId = v4()
    options.headers = {
      ...options.headers,
      ...(authToken && { Authorization: `Bearer ${authToken}` }),
      'trace-id': `${traceId}`
    }

    Sentry.setContext('trace-id', { traceId })

    return fetch(uri, options)
  }

  const httpLink = new HttpLink({
    uri: Constants.GRAPHQL_QUERY_URL,
    fetch: customFetch
  })

  let activeSocket, timedOut
  const websocketLink = new GraphQLWsLink(
    createClient({
      url: Constants.GRAPHQL_SUBSCRIPTIONS_URL,
      connectionParams: { authToken: authStore.useStore.getState().accessToken || '' },
      keepAlive: 10_000,
      on: {
        connected: (socket) => {
          console.info('websocket client TP connected!')
          activeSocket = socket
        },
        closed: () => {
          console.info('websocket client TP closed!')
        },
        error: () => {
          console.info('websocket client TP received an error!')
        },
        ping: (received) => {
          if (!received)
            // sent
            timedOut = setTimeout(() => {
              if (activeSocket.readyState === WebSocket.OPEN) {
                activeSocket.close(4408, 'Request Timeout')
              }
            }, 5_000) // wait 5 seconds for the pong and then close the connection
        },
        pong: (received) => {
          if (received) {
            clearTimeout(timedOut) // pong is received, clear connection close timeout
          }
        }
      }
    })
  )

  // The split function takes three parameters:
  //
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value
  // * The Link to use for an operation if the function returns a "falsy" value

  // queries and mutations will use HTTP as normal, subscriptions will use WebSocket
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    websocketLink,
    ApolloLink.from([retryLink, errorLink, httpLink])
  )

  return new ApolloClient({
    connectToDevTools: true,
    cache,
    link: splitLink,
    defaultOptions: {
      watchQuery: {
        nextFetchPolicy: 'cache-and-network'
      }
    }
  })
}

const hasStatusCode = (networkError: any): networkError is ServerError | ServerParseError => {
  return Boolean(networkError && networkError.statusCode)
}
