import ReactDOM from 'react-dom/client'

import App from './App.js'

import 'react-toastify/dist/ReactToastify.min.css'
import '../tailwind.css'

async function enableMocking() {
  if (import.meta.env.MODE === 'development') {
    const { setupMockWorker } = await import('./app/mock/worker.mock.ts')
    await setupMockWorker().start({ onUnhandledRequest: 'bypass' })
  }

  return Promise.resolve()
}

enableMocking().then(() => {
  ReactDOM.createRoot(document.getElementById('root')!).render(<App />)
})
